<template>
  <div id="merchant-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>

        <v-card>
          <v-card-text>
            <div class="d-flex align-center">
              <v-text-field
                v-model="form.created_at_from"
                type="date"
                label="Day Start"
                class="mr-2"
              />

              <v-text-field
                v-model="form.created_at_to"
                type="date"
                label="Day End"
                class="mr-2"
              />

              <v-chip
                class="cursor-pointer"
                @click="setDates(-1, -1)"
              >
                Yesterday
              </v-chip>

              <v-chip
                class="cursor-pointer"
                @click="setDates(-6, -1)"
              >
                Last 7 days
              </v-chip>

              <act-as-channel-options class="ml-2" />

              <v-select
                v-model="extraReportFilter"
                class="ml-2"
                style="width: 100px;"
                placeholder="Show Options"
                :items="extraReportOptions"
                item-text="title"
                item-value="value"
              ></v-select>

              <v-btn
                color="primary"
                :loading="loading"
                @click="reloadAll"
              >
                Show
              </v-btn>

              <v-btn
                v-show="currentTab === 0"
                color="success"
                :loading="loading"
                @click="generateOrderReport().then(loadOrderReports)"
              >
                Generate +
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-tabs
          v-model="currentTab"
          show-arrows
          class="user-tabs mt-2"
        >
          <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :disabled="tab.disabled"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="currentTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <channel-order-reports
              :items="orderReports"
              :loading="loading"
              :options.sync="options"
              :server-items-length="orderReportsTotal"
              @updated="reloadAll"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ActAsChannelOptions } from '@/components'
import router from '@/router'
import { mdiTable } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import ChannelOrderReports from './overview/ChannelOrderReports.vue'
import useChannelOrderReports from './useChannelOrderReports'

export default {
  components: {
    ActAsChannelOptions,
    ChannelOrderReports,
  },

  setup() {
    // UnRegister on leave
    const {
      form, orderReports, options, orderReportsTotal, reloadAll, loadOrderReports, generateOrderReport, loading, extraReportFilter, extraReportOptions,
    } = useChannelOrderReports()

    const tabs = computed(() => [{ icon: mdiTable, title: 'REPORTS XLSX' }])

    const currentTab = ref(0)

    const switchToDefaultTab = () => {
      const tab = router.currentRoute.query.tab || null
      const index = tabs.value.findIndex(t => t.title === tab)
      currentTab.value = Math.max(0, index)
    }

    onMounted(() => {
      // reloadMerchant().then(switchToDefaultTab)
      switchToDefaultTab()
    })

    const setDates = (fromDays = -1, toDays = -1) => {
      form.value.created_at_from = dayjs().add(fromDays, 'days').startOf('day').format('YYYY-MM-DD')
      form.value.created_at_to = dayjs().add(toDays, 'days').endOf('day').format('YYYY-MM-DD')
      reloadAll()
    }

    return {
      tabs,
      currentTab,

      form,
      orderReports,
      options,
      orderReportsTotal,
      reloadAll,
      loadOrderReports,
      generateOrderReport,
      loading,

      setDates,
      extraReportFilter,
      extraReportOptions,
    }
  },
}
</script>
